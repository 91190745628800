<template lang="pug">
.om-personalization-card
  .om-personalization-card-preview
    .om-personalization-card-preview-overlay(
      @click="learnMore"
      data-track-property="change:personalization.learn"
    )
      div {{ $t('learnMore') }}
    img(v-if="hasImage" :src="require(`@/assets/admin/img/personalization${this.card.src}`)")

  .om-personalization-card-content
    .om-personalization-card-content-name.cursor-pointer {{ card.title }}
    .om-personalization-card-content-targeting {{ card.description }}
    .om-personalization-card-content-controls
      .om-personalization-card-learn-more.mt-2
        om-link(
          withIconRight
          primary
          @click="learnMore"
          data-track-property="change:personalization.learn"
        ) {{ $t('learnMore') }}
</template>

<script>
  export default {
    props: {
      card: {
        type: Object,
        require: true,
      },
    },
    computed: {
      hasImage() {
        return !!this.card.src;
      },
    },
    methods: {
      learnMore() {
        window.open(this.card.link, '_blank');
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .om-personalization-card
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    border-radius: 8px

    &-preview
      width: 100%
      position: relative
      transition: .3s
      z-index: 9
      border-radius: 8px 8px 0 0
      overflow: hidden
      display: flex
      align-items: center
      justify-content: center
      aspect-ratio: 157 / 89
      background-color: #FFF7F2
      padding: 1rem

      img
        height: 100%

      &-overlay
        position: absolute
        width: 100%
        height: 100%
        z-index: 999
        opacity: 0
        transition: .3s
        display: flex
        align-items: center
        justify-content: center
        background: rgba(80, 87, 99, 0.7)
        cursor: pointer
        font-size: 14px
        font-weight: 700
        color: white
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.5)
        overflow: hidden
        border-top-left-radius: 8px
        border-top-right-radius: 8px
      &:hover
        .om-personalization-card-preview-overlay
          opacity: 1
          overflow: hidden

    &-content
      padding: 1rem 2rem 1.5rem
      max-width: 23.75rem

      &-name
        font-weight: 700
        font-size: 1.2rem
        line-height: 1.2
        margin-bottom: 0.5rem
        &:hover
          color: $om-orange-500

      &-targeting
        color: $om-gray-700
        font-size: .9375rem
        line-height: 1.5
        min-height: 4.5rem

      &-controls
        display: flex
        margin-top: auto
        justify-content: flex-end
</style>
