<template lang="pug">
om-modal.dc-personalization-modal(
  :name="name"
  :width="1296"
  color="light"
  :clickToClose="false"
  :scrollable="true"
  @closeClicked="close"
  @beforeOpen="beforeOpen"
  :class="type"
)
  template(#modal-body)
    .personalization
      .d-flex
        .left-col.d-flex.justify-content-center.flex-column
          om-heading(h1) {{ $t(`templateChooser.personalization.${type}.heading1`) }}
          om-body-text.description(bt400lg) {{ $t(`templateChooser.personalization.${type}.description`) }}
          .d-flex.justify-content-start.buttons-wrapper
            om-button.confirm-btn(primary large @click="createDynamicContent") {{ $t('templateChooser.personalization.common.getStarted') }}
            om-button.cancel-btn(secondary large @click="learnMore") {{ $t('templateChooser.personalization.common.learnMore') }}
          om-checkbox#doNotShowMultiCampaignModal.mr-2(
            v-if="isMultiCampaignABTest"
            @input="saveHasSeenMultiCampaignModal()"
            :value="getHasSeenMultiCampaignModal()"
            :label="$t(`templateChooser.personalization.${type}.doNotShowAgain`)"
          )
        .right-col
          template(v-if="isAbTest")
            img.mx-auto.img-fluid(
              src="@/assets/admin/img/personalization/abTest-personalization.png"
            )
          template(v-else-if="isMultiCampaignABTest")
            img.mx-auto.img-fluid(
              src="@/assets/admin/img/personalization/multi-campaign-personalization.png"
            )
          template(v-else)
            img.mx-auto.img-fluid(
              src="@/assets/admin/img/personalization/page-personalization.png"
            )
      .center-content.d-flex.justify-content-center.flex-column(v-if="!isMultiCampaignABTest")
        om-heading.text-center(h2) {{ $t(`templateChooser.personalization.${type}.heading2`) }}
        .personalization-cards.d-flex.justify-content-center
          template(v-for="card in cards")
            personalization-card(:card="card")
      .footer-content.d-flex.justify-content-center.flex-column
        om-heading.footer-title.text-center(h2) {{ $t(`templateChooser.personalization.common.footerHeading`) }}
        .personalization-steps.d-flex.justify-content-center
          template(v-for="step in steps")
            .personalization-step-wrapper
              .personalization-step-icon
                component(:is="step.icon")
              .personalization-step-title {{ step.stepIndex }}
              .personalization-step-description
                om-heading.text-center(h3) {{ step.title }}
      .footer-action
        om-button.confirm-btn(primary large @click="createDynamicContent") {{ $t('templateChooser.personalization.common.getStarted') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import PersonalizationCard from '@/components/TemplateChooser/components/cards/PersonalizationCard.vue';
  import * as StepSvgs from '@/components/TemplateChooser/components/modals/svgs';
  import { lsStore, lsRetrieve } from '@/helpers/localStorage';

  const images = require.context('@/assets/admin/img/personalization', true, /\.png$/);

  export default {
    components: { PersonalizationCard, ...StepSvgs },
    data: () => ({
      name: 'dc-personalization',
      type: null,
      HAS_SEEN_MULTI_CAMPAIGN_MODAL_KEY: 'hasSeenMultiCampaignModal',
    }),
    computed: {
      ...mapGetters(['getLocale']),
      isAbTest() {
        return this.type === 'abTest';
      },
      isMultiCampaignABTest() {
        return this.type === 'multiCampaignABTest';
      },
      cards() {
        let CARD_COUNT = 3;
        if (this.isMultiCampaignABTest) {
          CARD_COUNT = 0;
        }
        if (this.isAbTest) {
          CARD_COUNT = 2;
        }
        const cards = [];
        const links = {
          page: [
            'https://www.optimonk.com/tactics/boost-facebook-ads-roi-personalizing-landing-pages/',
            'https://www.optimonk.com/tactics/personal-greetings/',
            'https://www.optimonk.com/tactics/personalized-product-messaging/',
          ],
          abTest: [
            'https://www.optimonk.com/tactics/landing-page-headlines-with-different-value-propositions/',
            'https://www.optimonk.com/smart-a-b-testing/',
          ],
        };

        for (let i = 1; i <= CARD_COUNT; i++) {
          cards.push({
            src: images
              .keys()
              .find((imageName) => imageName === `./${this.type}/card-${i}.png`)
              ?.slice(1),
            title: this.$t(`templateChooser.personalization.${this.type}.cards.card-${i}.title`),
            description: this.$t(
              `templateChooser.personalization.${this.type}.cards.card-${i}.description`,
            ),
            link: this.type && links[this.type][i - 1],
          });
        }

        return cards;
      },
      steps() {
        const steps = [];
        const icons = ['step1', 'step2-page', 'step3'];
        if (this.isAbTest) icons[1] = 'step2-ab';

        for (let i = 1; i <= 3; i++) {
          steps.push({
            icon: icons[i - 1],
            stepIndex: this.$t(`templateChooser.personalization.common.stepTitle`, {
              index: i,
            }).toUpperCase(),
            title: this.$t(
              `templateChooser.personalization.${this.type}.footer.step-${i}.description`,
            ),
          });
        }

        return steps;
      },
      accountId() {
        return this.$route.params.userId;
      },
    },
    methods: {
      close() {
        this.$modal.hide(this.name);
        this.$emit('close');
      },
      beforeOpen(event) {
        this.type = event.params.type;
      },
      learnMore() {
        let link = this.isAbTest
          ? 'https://support.optimonk.com/hc/en-us/articles/13393216666258'
          : 'https://support.optimonk.com/hc/en-us/articles/13388621500050';
        if (this.type === 'multiCampaignABTest') {
          link =
            this.getLocale === 'en'
              ? 'https://support.optimonk.com/hc/en-us/articles/8840643937554-Multi-Campaign-A-B-Test'
              : 'https://support.optimonk.hu/hc/hu/articles/9060824894866-K%C3%ADs%C3%A9rletek ';
        }

        window.open(link, '_blank');
      },
      createDynamicContent() {
        this.$modal.hide('dc-personalization');
        if (this.type === 'multiCampaignABTest') {
          this.$router.push({ name: 'experiments', query: { startFlow: true } });
          return;
        }
        this.$router.push({
          name: 'new_campaign_flow_dc',
          query: { ...this.$route.query, type: this.type },
        });
      },
      saveHasSeenMultiCampaignModal() {
        let hasSeenMultiCampaignModal = lsRetrieve(this.HAS_SEEN_MULTI_CAMPAIGN_MODAL_KEY);

        if (hasSeenMultiCampaignModal?.[this.accountId]) {
          hasSeenMultiCampaignModal[this.accountId] = true;
        } else {
          hasSeenMultiCampaignModal = {
            ...hasSeenMultiCampaignModal,
            [this.accountId]: true,
          };
        }
        lsStore(this.HAS_SEEN_MULTI_CAMPAIGN_MODAL_KEY, hasSeenMultiCampaignModal);
      },
      getHasSeenMultiCampaignModal() {
        const hasSeenMultiCampaignModal = lsRetrieve(this.HAS_SEEN_MULTI_CAMPAIGN_MODAL_KEY);
        return hasSeenMultiCampaignModal?.[this.accountId] || false;
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .dc-personalization-modal
    &.multiCampaignABTest
      .brand-modal-header
        min-height: 50px
      .brand-modal-body .left-col
        padding-right: 20px
      .footer-content
        margin-top: 5rem
    &.v--modal-overlay:not(.centered-modal) .v--modal
      top: 4.375rem !important
      border-radius: 1.25rem
    .v--modal-background-click
      margin-bottom: 5.375rem
    @media screen and (max-width: 1399px)
      .v--modal
        width: 90% !important
        margin: 0 7.5% !important
    .v--modal
      border-radius: 20px
      .brand-modal
        border-radius: 20px
        .brand-modal-header
          border-radius: 20px
          padding: unset
        .brand-modal-body
          padding: 1rem 2.857rem
      .personalization
        .left-col
          gap: 32px
        .right-col
          width: 100%
          height: 100%
        .buttons-wrapper
          gap: 16px
        .center-content
          margin-top: 80px
          margin-bottom: 100px
          gap: 40px
          .personalization-cards
            gap: 40px
      .footer
        &-content
          .personalization-steps
            gap: 32px
          .personalization-step
            &-wrapper
              display: flex
              flex-direction: column
              max-width: 19.6875rem
            &-icon
              width: 3.25rem
              height: 3.25rem
              display: flex
              justify-content: center
              align-items: center
              align-self: center
              border-radius: 6.25rem
              padding: 0.5625rem 0.5625rem
              background: $om-peach-200
            &-title
              text-align: center
              margin: 1.25rem 0 1.25rem 0
              color: $om-orange-500
              font-weight: 500
              font-size: 1rem
        &-title
          margin-bottom: 60px
        &-action
          display: flex
          justify-content: center
          margin-top: 100px
</style>
